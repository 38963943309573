/**
 * Fix Bootstrap modal for use with CKEditor and Select2
 *
 * @see https://ckeditor.com/old/comment/127719#comment-127719
 * @see https://stackoverflow.com/a/44588516
 * @see https://stackpath.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.js
 * @see https://dev.ckeditor.com/attachment/ticket/12525/solution-for-all-bugs-ckeditor-in-bootstrap-modal-test.html
 */
$.fn.modal.Constructor.prototype.enforceFocus = function() {
    $(document)
        .off('focusin.bs.modal')
        .on(
            'focusin.bs.modal',
            $.proxy(function(e) {
                if(
                    document !== e.target
                    && this.$element[0] !== e.target
                    && !this.$element.has(e.target).length
                    && $(e.target).parentsUntil('*[role="dialog"]').length === 0
                /*
                // CKEditor v4 fix
                && !$(e.target.parentNode).hasClass('cke_dialog_ui_input_select')
                && !$(e.target.parentNode).hasClass('cke_dialog_ui_input_textarea')
                && !$(e.target.parentNode).hasClass('cke_dialog_ui_input_text')
                // Select2 fix
                && !$(e.target).closest('.select2-container').length
                */
                ) {
                    this.$element.trigger('focus')
                }
            }, this)
        );
};

/**
 * Debounces a function which needs to be called repeatedly for a period of time.
 *
 * @param {Function} func The function to debounce.
 * @param {Number} wait The waiting time.
 * @param {Boolean} [immediate] `true` to trigger the function at the beginning, `false` to trigger it at the end.
 * @return {Function} The debounced function.
 * @see https://davidwalsh.name/javascript-debounce-function
 */
function debounce(func, wait, immediate) { // eslint-disable-line no-unused-vars
    var timeout;

    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if(!immediate) {
                func.apply(context, args);
            }
        };
        var callNow = immediate && !timeout;

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);

        if(callNow) {
            func.apply(context, args);
        }
    };
}

/**
 * Throttles a function which needs to be called repeatdly for a period of time.
 *
 * @param {Function} func The function to throttle.
 * @param {Number} wait The waiting time.
 * @return {Function} The throttled function.
 * @see https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf
 */
function throttle(func, wait) { // eslint-disable-line no-unused-vars
    var timer;
    var lastRanAt;

    return function() {
        var context = this;
        var args = arguments;

        if(!lastRanAt) {
            func.apply(context, args);
            lastRanAt = Date.now();
        }
        else {
            clearTimeout(timer);
            timer = setTimeout(
                function() {
                    if ((Date.now() - lastRanAt) >= wait) {
                        func.apply(context, args)
                        lastRanAt = Date.now()
                    }
                },
                wait - (Date.now() - lastRanAt)
            );
        }
    };
}

/**
 * Rounds a number using a specific precision.
 *
 * @param {number|*} number The number to format.
 * @param {int} precision The precision to use.
 * @returns {number} The formatted number.
 * @see https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary#answer-12830454
 */
function round(number, precision) {
    if('number' !== typeof number) {
        number = parseFloat(number);
    }

    return +number.toFixed(precision);
}

/**
 * Formats a number.
 *
 * @param {number} number The number to format.
 * @returns {string} The formatted number.
 */
function formatNumber(number) {
    if('undefined' === typeof this.formatter) {
        this.formatter = new Intl.NumberFormat($(document.documentElement).attr('lang'));
    }

    return this.formatter.format(number);
}

/**
 * Formats an input value as a size.
 *
 * @param {number} size A size in bytes.
 * @param {number} [precision] The precision to use.
 * @return {string} The formatted string.
 * @see https://stackoverflow.com/a/3019290
 * @todo i18n
 */
function formatSize(size, precision) { // eslint-disable-line no-unused-vars
    var suffixes = ['o', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
    precision = 'undefined' !== typeof precision ? precision : 2;

    if(0 === size) {
        return '0 ' + suffixes[0];
    }

    var index = Math.floor(Math.log(size) / Math.log(1000));

    if(index >= suffixes.length) {
        return size;
    }

    size = round(size / Math.pow(1000, index), precision);
    size = formatNumber(size);

    return size + ' ' + suffixes[index];
}

/**
 * Formats an input value as a duration.
 *
 * @param {int} duration A duration in seconds.
 * @return {string} The formatted string.
 * @see https://stackoverflow.com/questions/5539028/converting-seconds-into-hhmmss/5539081#answer-5539081
 */
function formatDuration(duration) { // eslint-disable-line no-unused-vars
    if(duration >= 0) {
        var days, hours, minutes, seconds;

        if(duration < 1) {
            // Format to milliseconds
            duration = Math.round(duration * 1000) + ' ms';
        }
        else if(duration < 60) {
            // Format to seconds
            duration = round(duration, 3) + ' s';
        }
        else if(duration < 3600) {
            // Format to minutes and seconds
            minutes = Math.floor(duration / 60);
            seconds = Math.floor(duration % 60);

            duration = ('0' + minutes).slice(-2) + ' m ' + ('0' + seconds).slice(-2);
        }
        else if(duration < 86400) {
            // Format to hours, minutes and seconds
            hours = Math.floor(duration / 3600);
            duration %= 3600;
            minutes = Math.floor(duration / 60);
            seconds = Math.floor(duration % 60);

            duration = ('0' + hours).slice(-2) + ' h ' + ('0' + minutes).slice(-2) + ' m ' + ('0' + seconds).slice(-2);
        }
        else {
            // Format to days, hours, minutes and seconds
            days = Math.floor(duration / 86400);
            duration %= 86400;
            hours = Math.floor(duration / 3600);
            duration %= 3600;
            minutes = Math.floor(duration / 60);
            seconds = Math.floor(duration % 60);

            duration = formatNumber(days) + ' j ' + ('0' + hours).slice(-2) + ' h ' + ('0' + minutes).slice(-2) + ' m ' + ('0' + seconds).slice(-2);
        }
    }

    return duration;
}

/**
 * Encodes a string into HTML entities.
 *
 * @param {string} input The string to encode.
 * @return {string} The escaped string.
 * @see https://stackoverflow.com/questions/1787322/htmlspecialchars-equivalent-in-javascript#answer-4835406
 * @see https://secure.php.net/htmlspecialchars
 */
function htmlspecialchars(input) { // eslint-disable-line no-unused-vars
    if('string' === typeof input) {
        var map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
        };

        return input.replace(
            /[&<>"']/g,
            function(m) {
                return map[m];
            }
        );
    }

    return input;
}

/**
 * Decodes a Unicode string.
 *
 * @param {string} input The string to decode.
 * @return {string} The decoded string.
 * @see https://stackoverflow.com/questions/7885096/how-do-i-decode-a-string-with-escaped-unicode#answer-7885499
 */
function decodeUnicode(input) { // eslint-disable-line no-unused-vars
    return input.replace(
        /\\u([\d\w]{4})/gi,
        function(match, grp) {
            return String.fromCharCode(parseInt(grp, 16));
        }
    );
}

/**
 * Encodes a string into base64.
 *
 * @param {string} input The string to encode.
 * @returns {string} The encoded string.
 * @see https://developer.mozilla.org/fr/docs/D%C3%A9coder_encoder_en_base64#Premi%C3%A8re_solution_%E2%80%93_%C3%A9chapper_la_cha%C3%AEne_avant_de_l'encoder
 */
function base64Encode(input) { // eslint-disable-line no-unused-vars
    return btoa(unescape(encodeURIComponent(input)));
}

/**
 * Decodes a string from base64.
 *
 * @param {string} input The string to decode.
 * @returns {string} The decoded string.
 * @see https://developer.mozilla.org/fr/docs/D%C3%A9coder_encoder_en_base64#Premi%C3%A8re_solution_%E2%80%93_%C3%A9chapper_la_cha%C3%AEne_avant_de_l'encoder
 */
function base64Decode(input) { // eslint-disable-line no-unused-vars
    return decodeURIComponent(escape(atob(input)));
}

/**
 * Tests whether an input type is supported, default is `null` when test doesn't exist.
 *
 * @param {string} type The input type to test.
 * @returns {boolean|null} `true` if the input type is supported, `false` otherwise.
 * @see https://gomakethings.com/how-to-check-if-a-browser-supports-native-input-date-pickers/
 */
function isInputTypeSupported(type) { // eslint-disable-line no-unused-vars
    this.types = this.types || {};

    if('undefined' !== typeof this.types[type]) {
        return this.types[type];
    }

    switch(type) {
        case 'color':
        case 'date':
        case 'time':
        case 'week':
            var value = '!';
            var input = document.createElement('input');
            input.setAttribute('type', type);
            input.setAttribute('value', value);

            return this.types[type] = type === input.type && input.value !== value;

        default:
            return null;
    }
}

/**
 * Gets a valid Select2 language.
 *
 * @param {String} language The original language.
 * @return {String} The Select2 language.
 */
function select2Language(language) { // eslint-disable-line no-unused-vars
    var languages = [language];

    if(language.indexOf('-') > -1 || language.indexOf('_') > -1) {
        languages.push(language.substring(0, 2));
    }

    for(var i = 0; i < languages.length; ++i) {
        switch(languages[i]) {
            case 'af':
            case 'ar':
            case 'az':
            case 'bg':
            case 'bn':
            case 'ca':
            case 'cs':
            case 'da':
            case 'de':
            case 'dsb':
            case 'el':
            case 'en':
            case 'es':
            case 'et':
            case 'eu':
            case 'fa':
            case 'fi':
            case 'fr':
            case 'gl':
            case 'he':
            case 'hi':
            case 'hsb':
            case 'hu':
            case 'hy':
            case 'id':
            case 'is':
            case 'it':
            case 'ja':
            case 'ka':
            case 'km':
            case 'ko':
            case 'lt':
            case 'lv':
            case 'mk':
            case 'ms':
            case 'nb':
            case 'ne':
            case 'nl':
            case 'pl':
            case 'ps':
            case 'pt-BR':
            case 'pt':
            case 'ro':
            case 'ru':
            case 'sk':
            case 'sl':
            case 'sq':
            case 'sr-Cyrl':
            case 'sr':
            case 'sv':
            case 'th':
            case 'tk':
            case 'tr':
            case 'uk':
            case 'vi':
            case 'zh-CN':
            case 'zh-TW':
                return language;

            case 'fr-FR':
                return 'fr';

            case 'en-US':
            case 'en-UK':
                return 'en';
        }
    }

    return 'en';
}

(function() {
    var $body = $('body');
    var $wrapper = $('#wrapper');
    var $loader = $('#loader');
    var currentRequest = null;
    var loadingAnimation = null;
    var immutableClass = [];
    var initialClasses = $body.attr('class').match(/logged-(in|out)/);

    if(null !== initialClasses) {
        immutableClass.push(initialClasses[0]);
    }

    var BO = (function() {
        // Private methods
        /**
         * Updates the wrapper using the response's contents.
         *
         * @param {string} html The response's contents.
         * @param {string[]} classes An array of classes to add to the body.
         */
        function updateWrapper(html, classes) {
            if('' !== html) {
                $wrapper.html(html).find('[autofocus]').first().focus();
            }
            else {
                $wrapper.empty();
            }

            $body.attr('class', immutableClass.concat(classes).join(' '));
            $body.trigger('bo:loading-url:after');
        }

        /**
         * Performs an AJAX request. Every AJAX request on the back office should go through this function so as to
         * handle session expiration during an AJAX request so that the user can be redirected appropriately.
         *
         * @param {string} method The request's method, usually "get" or "post".
         * @param {string} url The request's path.
         * @param {Object|FormData} data The request's data.
         * @param {Function} successHandler Method to perform on success.
         * @param {Function} errorHandler Method to perform on failure.
         * @param {Object} headers Headers to add to the request.
         * @param {boolean} useLoader `true` to display a loader, `false` or `undefined` not to.
         * @param {boolean} disconnectedAsError `true` to handle user disconnections as an error, `false` otherwise.
         * @returns {*} The request's deferred object.
         */
        function doRequest(method, url, data, successHandler, errorHandler, headers, useLoader, disconnectedAsError) {
            // Initialize vars
            useLoader = 'undefined' === typeof useLoader || !!useLoader;
            disconnectedAsError = 'undefined' !== typeof disconnectedAsError ? !!disconnectedAsError : false;

            if(useLoader) {
                BO.loader.show();
            }

            // Build options
            var options = {
                method: method,
                url: url,
            };

            // Deal with data
            if(null !== data) {
                options.data = data;
            }

            if(data instanceof FormData) {
                options.processData = false;
                options.contentType = false;
            }

            // Deal with headers
            if('object' === typeof headers) {
                options.beforeSend = function(xhr) {
                    for(var key in headers) {
                        if(Object.prototype.hasOwnProperty.call(headers, key)) {
                            xhr.setRequestHeader(key, headers[key]);
                        }
                    }
                };
            }

            currentRequest = $
                .ajax(options)
                .then(function(data, textStatus, xhr) {
                    var deferred = $.Deferred();

                    if(disconnectedAsError && 'disconnected' === xhr.getResponseHeader('x-user-status')) {
                        deferred.fail(xhr, 'disconnected', 'User is logged out.');
                    }
                    else {
                        deferred.resolve(data, textStatus, xhr);
                    }

                    return deferred;
                })
                .fail(function(xhr) {
                    // Handle 401 Unauthorized errors when session expires without reloading the page
                    // Handle 410 Gone errors when an application is removed from an user's accesses
                    if(
                        (401 === xhr.status || 410 === xhr.status)
                        && 'object' === typeof xhr.responseJSON
                        && null !== xhr.responseJSON
                        && 'string' === typeof xhr.responseJSON.location
                    ) {
                        location.href = xhr.responseJSON.location;
                    }
                })
                // User provided handlers
                .then(successHandler, errorHandler)
                .always(function() {
                    if(useLoader) {
                        BO.loader.hide();
                    }

                    currentRequest = null;
                })
            ;

            return currentRequest;
        }

        // Public methods
        return {
            loader: {
                /**
                 * Shows the loader.
                 */
                show: function() {
                    if(null !== loadingAnimation) {
                        loadingAnimation.stop();
                        loadingAnimation = null;
                    }

                    $loader.appendTo($wrapper).fadeIn(150);
                },
                /**
                 * Hides the loader if it is still displayed.
                 */
                hide: function() {
                    loadingAnimation = $loader.fadeOut(
                        120,
                        function() {
                            $(this).hide().appendTo($body);
                        }
                    );
                }
            },
            /**
             * Utility method to perform a request.
             *
             * @param {string} method The request's method.
             * @param {string} url The request's path.
             * @param {Object|FormData} data The request's data.
             * @param {*} [_] Deprecated parameter.
             * @param {Function} successHandler Method to perform on success.
             * @param {Function} errorHandler Method to perform on failure.
             * @param {Object} headers Headers to add to the request.
             * @param {boolean} useLoader `true` to display a loader, `false` or `undefined` not to.
             * @param {boolean} disconnectedAsError `true` to handle user disconnections as an error, `false` otherwise.
             * @returns {*} The request's deferred object.
             */
            doRequest: function(method, url, data, _, successHandler, errorHandler, headers, useLoader, disconnectedAsError) {
                return doRequest(
                    method,
                    url,
                    data,
                    successHandler,
                    errorHandler,
                    headers,
                    useLoader,
                    disconnectedAsError
                );
            },
            /**
             * Utility method to perform a GET request.
             *
             * @param {string} url The request's path.
             * @param {Object} data The request's data.
             * @param {Function} successHandler Method to perform on success.
             * @param {Function} errorHandler Method to perform on failure.
             * @param {Object} headers Headers to add to the request.
             * @param {boolean} useLoader `true` to display a loader, `false` or `undefined` not to.
             * @param {boolean} disconnectedAsError `true` to handle user disconnections as an error, `false` otherwise.
             * @returns {*} The request's deferred object.
             */
            doGet: function(url, data, successHandler, errorHandler, headers, useLoader, disconnectedAsError) {
                return doRequest(
                    'get',
                    url,
                    data,
                    successHandler,
                    errorHandler,
                    headers,
                    useLoader,
                    disconnectedAsError
                );
            },
            /**
             * Utility method to perform a POST request.
             *
             * @param {string} url The request's path.
             * @param {Object|FormData} data The request's data.
             * @param {*} [_] Deprecated parameter.
             * @param {Function} successHandler Method to perform on success.
             * @param {Function} errorHandler Method to perform on failure.
             * @param {Object} [headers] Headers to add to the request.
             * @param {boolean} [useLoader] `true` to display a loader, `false` or `undefined` not to.
             * @param {boolean} disconnectedAsError `true` to handle user disconnections as an error, `false` otherwise.
             * @returns {*} The request's deferred object.
             */
            doPost: function(url, data, _, successHandler, errorHandler, headers, useLoader, disconnectedAsError) {
                return doRequest(
                    'post',
                    url,
                    data,
                    successHandler,
                    errorHandler,
                    headers,
                    useLoader,
                    disconnectedAsError
                );
            },
            /**
             * Performs a GET request to update the page's contents. If necessary, additional stylesheets and scripts
             * can be loaded using HeadJS. Moreover, classes can be applied to the <body>.
             *
             * Should actions be perform before of after the page changes, two events are available: `bo:loading-url:before`
             * and `bo:loading-url:after`.
             *
             * @param {string} url The URL to load.
             * @param {Object} [data] Parameters to add to the URI if needed.
             * @param {Object} [headers] Additional headers to add to the request.
             * @returns {*} The request's deferred object.
             * @todo Handle title for history.
             */
            loadUrl: function(url, data, headers) {
                // Trigger loading event
                var loadingEvent = $.Event('bo:loading-url:before');
                loadingEvent.url = url;

                $body.trigger(loadingEvent);

                if(loadingEvent.isDefaultPrevented()) {
                    var deferred = $.Deferred();
                    deferred.reject();

                    return deferred;
                }

                return BO.doGet(
                    url,
                    data || {},
                    function(response) {
                        // Append new page to browser's history
                        history.pushState(
                            {},
                            'Titre',
                            url
                        );

                        if(response) {
                            // Build formatted files list for HeadJS
                            var requiredFiles = [];
                            var formattedFiles = [];
                            var item;

                            // Handle additional stylesheets
                            if('undefined' !== typeof response.stylesheets && $.isArray(response.stylesheets)) {
                                requiredFiles = requiredFiles.concat(response.stylesheets);
                            }

                            // Handle additional scripts
                            if('undefined' !== typeof response.scripts && $.isArray(response.scripts)) {
                                requiredFiles = requiredFiles.concat(response.scripts);
                            }

                            for(var i = 0; i < requiredFiles.length; i++) {
                                if('undefined' !== typeof requiredFiles[i].slug && 'undefined' !== typeof requiredFiles[i].path) {
                                    item = {};
                                    item[requiredFiles[i].slug] = requiredFiles[i].path;
                                    formattedFiles.push(item);
                                }
                                else if('string' === typeof requiredFiles[i]) {
                                    formattedFiles.push(requiredFiles[i]);
                                }
                            }

                            if(formattedFiles.length > 0) {
                                head.load(formattedFiles, function() {
                                    updateWrapper(response.html || '', response.classes || []);
                                });
                            }
                            else {
                                updateWrapper(response.html || '', response.classes || []);
                            }
                        }
                        else {
                            // @todo What should be done?
                        }

                        var deferred = $.Deferred();
                        deferred.resolve(response);

                        return deferred;
                    },
                    function(xhr, textStatus, error) {
                        console.error('Page "', url, '" couldn\'t be loaded: ', textStatus, ' | ', error);

                        if('object' === typeof xhr.responseJSON && null !== xhr.responseJSON) {
                            BootstrapDialog.show({
                                type: xhr.responseJSON.type || BootstrapDialog.TYPE_DANGER,
                                // @todo Where should default texts be found?
                                title: xhr.responseJSON.title || null,
                                message: xhr.responseJSON.message || null
                            });
                        }

                        var deferred = $.Deferred();
                        deferred.reject();

                        return deferred;
                    },
                    headers
                );
            },
            /**
             * Tries opening a form modal.
             *
             * @param {Object} [options] Options to customize the function.
             * @param {Object} [options.headers] Additional headers for the request.
             * @param {Object} [options.loadingError] Options to customize the error modal.
             * @param {string} [options.loadingError.type] Default modal type, one of `BootstrapDialog.TYPE_*`, overwritten in response by `type`.
             * @param {string} [options.loadingError.title] Default modal title, overwritten in response by `title`.
             * @param {string} [options.loadingError.message] Default modal message, overwritten in response by `message`.
             * @param {Object} [options.bootstrapOptions] The Bootstrap modal options (https://getbootstrap.com/docs/3.4/javascript/#modals-options).
             * @param {Function} [options.initializeModal] A function to initialize the modal, for example, using Select2, only parameter is the jQuery object for the modal.
             * @param {Function} [options.onSubmit] A function to perform before submitting the form, should return `true` to continue submitting it, `false` otherwise.
             * @param {Function} [options.buildFormData] A function to build the `FormData` on form submission, `this` is the `<form>` element.
             * @param {Function} [options.onLoaded] Callback triggered on successful loading, it must return a jQuery object of the modal.
             * @param {Function} [options.onLoadFailed] Callback triggered on failed loading.
             * @param {Function} [options.onLoadStarting] Callback triggered when loading starts, `this` is the clicked element.
             * @param {Function} [options.onLoadEnded] Callback triggered when loading end, either successfully or not, `this` is the clicked element.
             * @param {Function} [options.onSaved] Callback triggered on successful save, `this` is the modal's element.
             * @param {Function} [options.onFailed] Callback triggered on failed save, `this` is the modal's element.
             * @return {Function} The newly built function.
             */
            formModal: function(options) {
                var customization = {
                    headers: {},
                    loadingError: {
                        type: BootstrapDialog.TYPE_DANGER,
                        title: i18next.t('errors:loading.title'),
                        message: i18next.t('errors:loading.message')
                    },
                    bootstrapOptions: {},
                    /**
                     * Initializes the modal.
                     */
                    initializeModal: $.noop,
                    /**
                     * Triggered before submitting the form.
                     *
                     * This function should return `true` if the form can be submitted, `false` otherwise.
                     *
                     * @param {Function} $modal
                     * @returns {boolean} `true` to keep submitting, `false` otherwise.
                     */
                    onSubmit: function() {
                        return true;
                    },
                    /**
                     * Builds the `FormData`.
                     *
                     * @returns {FormData} The newly built `FormData`.
                     */
                    buildFormData: function() {
                        return new FormData(this);
                    },
                    /**
                     * Triggered on a successful load.
                     *
                     * @param {string} html The modal's contents.
                     * @returns {Object} The newly built modal.
                     */
                    onLoaded: function(html) {
                        var $html = $(html);
                        var $form = $html.find('form');
                        var $submitButton = $form.find('button[type="submit"]');

                        customization.initializeModal($html);

                        $form.on('submit', function(event) {
                            // Prevent default behavior
                            event.preventDefault();

                            // Display loader
                            $submitButton
                                .prepend('<i class="fas fa-spinner fa-spin"></i>')
                                .prop('disabled', true)
                            ;

                            // Delete previous errors
                            $form.find('.has-error')
                                .find('.help-block[data-temporary="true"]')
                                .remove()
                                .end()
                                .removeClass('has-error')
                            ;
                            $form.find('.form-errors')
                                .remove()
                            ;

                            if(!customization.onSubmit($html)) {
                                $submitButton
                                    .find('i')
                                    .remove()
                                    .end()
                                    .prop('disabled', false)
                                ;
                                return;
                            }

                            BO.doRequest(
                                $form.attr('method') || 'post',
                                $form.attr('action'),
                                customization.buildFormData.apply(this),
                                false,
                                customization.onSaved.bind($html.get(0)),
                                customization.onFailed.bind($html.get(0)),
                                {},
                                false
                            );
                        });

                        return $html;
                    },
                    /**
                     * Triggered when loading fails.
                     *
                     * @param {Object} xhr The XHR object.
                     */
                    onLoadFailed: function(xhr) {
                        var type = customization.loadingError.type;
                        var title = customization.loadingError.title;
                        var message = customization.loadingError.message;

                        if('object' === typeof xhr.responseJSON && null !== xhr.reponseJSON) {
                            type = xhr.responseJSON.type || type;
                            title = xhr.responseJSON.title || title;
                            message = xhr.responseJSON.message || message;
                        }

                        BootstrapDialog.show({
                            type: type,
                            title: title,
                            message: message
                        });
                    },
                    /**
                     * Triggered when loading starts.
                     */
                    onLoadStarting: function() {
                        var $this = $(this);
                        var $icon;

                        if($this.hasClass('btn')) {
                            // Links or buttons shown as a button
                            $icon = $this.find('i');

                            if(0 !== $icon.length) {
                                $this.data('had-icon', true);
                                $icon
                                    .data('previous-icon', $icon.attr('class'))
                                    .removeClass()
                                    .addClass('fas fa-spin fa-spinner')
                                ;
                            }
                            else {
                                $this
                                    .data('had-icon', false)
                                    .addClass('disabled')
                                    .prepend('<i class="fas fa-spin fa-spinner"></i>')
                                ;
                            }
                        }
                        else if($this.closest('.dropdown-menu').length > 0) {
                            // Dropdown button: https://getbootstrap.com/docs/3.4/components/#btn-dropdowns
                            $this
                                .closest('.dropdown-menu')
                                .prev('.btn')
                                .prop('disabled', true)
                                .prepend('<i class="fas fa-spin fa-spinner"></i>')
                                .closest('.btn-group')
                                .removeClass('open')
                            ;
                        }
                        else {
                            // Link with a nested icon
                            $icon = $this.find('i');

                            if(0 !== $icon.length) {
                                $icon
                                    .data('previous-icon', $icon.attr('class'))
                                    .removeClass()
                                    .addClass('fas fa-spin fa-spinner')
                                ;
                            }
                        }
                    },
                    /**
                     * Triggered when loading ends, successfully or not.
                     */
                    onLoadEnded: function() {
                        var $this = $(this);
                        var $icon;

                        if($this.hasClass('btn')) {
                            // Links or buttons shown as a button
                            $icon = $this.find('i');

                            if($this.data('had-icon')) {
                                $icon
                                    .removeClass()
                                    .addClass($icon.data('previous-icon'))
                                    .removeData('previous-icon')
                                ;
                            }
                            else {
                                $this
                                    .removeClass('disabled')
                                    .find('i')
                                    .remove()
                                ;
                            }

                            $this.removeData('had-icon');
                        }
                        else if($this.closest('.dropdown-menu').length > 0) {
                            // Dropdown button: https://getbootstrap.com/docs/3.4/components/#btn-dropdowns
                            var $button = $this.closest('.dropdown-menu').prev('.btn');

                            $button
                                .find('i:first')
                                .remove()
                                .end()
                                .prop('disabled', false)
                            ;
                        }
                        else {
                            // Link with a nested icon
                            $icon = $this.find('i');

                            if(0 !== $icon.length) {
                                $icon
                                    .removeClass()
                                    .addClass($icon.data('previous-icon'))
                                    .removeData('previous-icon')
                                ;
                            }
                        }
                    },
                    /**
                     * Triggered on successful save.
                     */
                    onSaved: function() {
                        $(this).modal('hide');
                        BO.loadUrl(location.href);
                    },
                    /**
                     * Triggered on failed save.
                     *
                     * @param {Object} xhr jQuery's XHR object.
                     */
                    onFailed: function(xhr) {
                        var $html = $(this);
                        var $form = $html.find('form');
                        var $submitButton = $form.find('button[type="submit"]');

                        if('object' === typeof xhr.responseJSON && null !== xhr.responseJSON) {
                            if('undefined' !== typeof xhr.responseJSON.errors) {
                                var errors = xhr.responseJSON.errors || [];
                                var formName = $form.attr('name');

                                for(var field in errors) {
                                    if(Object.prototype.hasOwnProperty.call(errors, field)) {
                                        if(formName !== field) {
                                            $form
                                                .find('[data-path~="' + field + '"], [data-field~="' + field + '"]')
                                                .addClass('has-error')
                                                .append('<span class="help-block" data-temporary="true">' + errors[field].join('<br/>') + '</span>')
                                            ;
                                        }
                                        else {
                                            $form.find('.modal-body').append('<p class="form-errors">' + errors[field].join('<br/>') + '</p>');
                                        }
                                    }
                                }
                            }
                            else
                            {
                                $form.find('.modal-body').append('<p class="form-errors">' + (xhr.responseJSON.message || i18next.t('errors:saving.message')) + '</p>');
                            }
                        }
                        else {
                            $form.find('.modal-body').append('<p class="form-errors">' + i18next.t('errors:saving.message') + '</p>');
                        }

                        $submitButton
                            .find('i')
                            .remove()
                            .end()
                            .prop('disabled', false)
                        ;
                    }
                };
                $.extend(true, customization, options || {});

                // Do not break previous parameter's name
                if('function' === typeof customization.loadingCallback) {
                    customization.onLoaded = customization.loadingCallback;
                }

                return function(event) {
                    // Prevent default behavior
                    event.preventDefault();

                    var self = this;
                    var $this = $(this);

                    // Is it already loading?
                    if($this.data('loading')) {
                        return;
                    }

                    $this.data('loading', true);
                    customization.onLoadStarting.call(this);

                    BO
                        .doGet(
                            $this.data('url') || $this.attr('href'),
                            null,
                            function(response) {
                                head.load(
                                    response.stylesheets.concat(response.scripts),
                                    function() {
                                        customization
                                            .onLoaded(response.html)
                                            .appendTo($body)
                                            .modal(customization.bootstrapOptions)
                                            .modal('show')
                                        ;
                                    }
                                );
                            },
                            customization.onLoadFailed.bind(null),
                            customization.headers,
                            false
                        )
                        .always(function() {
                            customization.onLoadEnded.call(self);

                            $this.removeData('loading');
                        })
                    ;
                };
            },
            /**
             * Sets up an AJAX form embedded directly in a page.
             *
             * @param {jQuery} $form The form.
             * @param {Object} [options] Options to customize the set up.
             * @param {Function} [options.onSubmission] Customizes the pre-submission callback and allows to prevent it, by default, does nothing.
             * @param {Function} [options.buildHeaders] Customizes the headers building function, by default, no additional headers.
             * @param {Function} [options.buildFormData] Customizes the form data building function, by default, uses `FormData(this)`.
             * @param {Function} [options.onSuccess] Customizes the success callback, by default, does nothing.
             * @param {Function} [options.onFailure] Customizes the error callback, by default, shows errors.
             * @return {jQuery} The form.
             */
            setUpForm: function($form, options) {
                var $submitButton = $form.find('button[type="submit"], input[type="submit"]');
                var defaultOptions = {
                    /**
                     * Triggered before submitting a form.
                     *
                     * @param {jQuery} $form The form.
                     * @return {boolean} `true` to continue submitting, `false` otherwise.
                     */
                    onSubmission: function() {
                        return true;
                    },
                    /**
                     * Builds additional headers to send with the request.
                     *
                     * `this` is the form being submitted.
                     *
                     * @return {Object} The newly built headers.
                     */
                    buildHeaders: function() {
                        return {};
                    },
                    /**
                     * Builds the form data to send.
                     *
                     * `this` is the form being submitted.
                     *
                     * @return {FormData|Object} The newly built form data.
                     */
                    buildFormData: function() {
                        return new FormData(this);
                    },
                    /**
                     * Triggered on a successful submission.
                     *
                     * @param {*} response The response.
                     */
                    onSuccess: function() {
                    },
                    /**
                     * Triggered on a failed submission.
                     *
                     * @param {Object} xhr The jQuery XHR object.
                     * @param {Object} xhr.responseJSON The response as JSON.
                     */
                    onFailure: function(xhr) {
                        var type = BootstrapDialog.TYPE_DANGER;
                        var title = i18next.t('errors:submitting.title');
                        var message = i18next.t('errors:submitting.message');

                        if('object' === typeof xhr.responseJSON && null !== xhr.responseJSON) {
                            type = xhr.responseJSON.type || type;
                            title = xhr.responseJSON.title || title;
                            message = xhr.responseJSON.message || message;

                            if('object' === typeof xhr.responseJSON.errors && null !== xhr.responseJSON.errors) {
                                var rawErrors = xhr.responseJSON.errors;
                                var formErrors = [];
                                var formName = $form.attr('name');

                                for(var path in rawErrors) {
                                    if(!Object.prototype.hasOwnProperty.call(rawErrors, path)) {
                                        continue;
                                    }

                                    if(formName !== path) {
                                        // Field error
                                        $form
                                            .find('[data-path~="' + path + '"]')
                                            .addClass('has-error')
                                            .append('<span class="help-block" data-temporary="true">' + rawErrors[path].join('<br/>') + '</span>')
                                        ;
                                    }
                                    else {
                                        // Form error
                                        formErrors = formErrors.concat(rawErrors[path]);
                                    }
                                }

                                if(formErrors.length > 0) {
                                    message = formErrors.map(function(error) {
                                        return '<li>' + htmlspecialchars(error) + '</li>';
                                    });

                                    BootstrapDialog.show({
                                        type: type,
                                        title: i18next.t('errors:submitting.title'),
                                        message: '<ul>' + message + '</ul>'
                                    });
                                }
                            }
                            else {
                                BootstrapDialog.show({
                                    type: type,
                                    title: title,
                                    message: message
                                });
                            }
                        }
                    }
                };
                options = $.extend(true, defaultOptions, options || {});

                $form.on('submit', function(event) {
                    // Prevent default behavior
                    event.preventDefault();

                    // Display loader
                    $submitButton
                        .prop('disabled', true)
                        .filter('button')
                        .prepend('<i class="fas fa-spinner fa-spin"></i>')
                    ;

                    // Delete previous errors
                    $form
                        .find('.has-error')
                        .find('.help-block[data-temporary="true"]')
                        .remove()
                        .end()
                        .removeClass('has-error')
                        .end()
                    ;

                    if(!options.onSubmission($form)) {
                        $submitButton
                            .find('i')
                            .remove()
                            .end()
                            .prop('disabled', false)
                        ;

                        return;
                    }

                    BO
                        .doRequest(
                            $form.attr('method') || 'post',
                            $form.attr('action'),
                            options.buildFormData.apply($form.get(0)),
                            undefined,
                            options.onSuccess,
                            options.onFailure,
                            options.buildHeaders.apply($form.get(0)),
                            false
                        )
                        .always(function() {
                            $submitButton
                                .find('i')
                                .remove()
                                .end()
                                .prop('disabled', false)
                            ;
                        })
                    ;
                });

                return $form;
            },
            /**
             * Displays a modal to confirm deleting an element.
             *
             * @param {Object} options Options to customize the function.
             * @param {Object} options.modal Options to customize the confirmation modal.
             * @param {string} [options.modal.type] Modal type, one of `BootstrapDialog.TYPE_*`.
             * @param {string} options.modal.title Modal title.
             * @param {string} options.modal.message Modal message.
             * @param {string} [options.modal.cancelLabel] Modal's cancel button's label.
             * @param {string} [options.modal.cancelClass] Modal's cancel button's class.
             * @param {string} [options.modal.okLabel] Modal's ok button's label.
             * @param {string} [options.modal.okClass] Modal's ok button's class.
             * @param {Object} options.error Options to customize the deletion error modal.
             * @param {string} [options.error.type] Default modal type, one of `BootstrapDialog.TYPE_*`, overwritten in response by `type`.
             * @param {string} options.error.title Default modal title, overwritten in response by `title`.
             * @param {string} options.error.message Default modal message, overwritten in response by `message`.
             * @param {Function} [options.onSuccessful] Callback on successful calls.
             * @param {Function} [options.onCancelled] Callback on "Cancel" button.
             * @param {Function} [options.onFailed] Callback on failed calls.
             * @return {Function} The newly built function.
             */
            confirmationModal: function(options) {
                var startLoader = function($this) {
                    if($this.hasClass('btn')) {// Links or buttons shown as a button

                        $this
                            .addClass('disabled')
                            .prepend('<i class="fas fa-spin fa-spinner"></i>')
                        ;
                    }
                    else if($this.closest('.dropdown-menu').length > 0) {
                        // Dropdown button: https://getbootstrap.com/docs/3.4/components/#btn-dropdowns
                        $this
                            .closest('.dropdown-menu')
                            .prev('.btn')
                            .prop('disabled', true)
                            .prepend('<i class="fas fa-spin fa-spinner"></i>')
                            .closest('.btn-group')
                            .removeClass('open')
                        ;
                    }
                    else {
                        // Link with a nested icon
                        var $icon = $this.find('i');

                        if(0 !== $icon.length) {
                            $icon
                                .data('previous-icon', $icon.attr('class'))
                                .removeClass()
                                .addClass('fas fa-spin fa-spinner')
                            ;
                        }
                    }
                };
                var stopLoader = function($this) {
                    if($this.hasClass('btn')) {
                        // Links or buttons shown as a button
                        $this
                            .removeClass('disabled')
                            .find('i')
                            .remove();
                    }
                    else if($this.closest('.dropdown-menu').length > 0) {
                        // Dropdown button: https://getbootstrap.com/docs/3.4/components/#btn-dropdowns
                        var $button = $this.closest('.dropdown-menu').prev('.btn');

                        $button
                            .find('i:first')
                            .remove()
                            .end()
                            .prop('disabled', false)
                        ;
                    }
                    else {
                        // Link with a nested icon
                        var $icon = $this.find('i');

                        if(0 !== $icon.length) {
                            $icon
                                .removeClass()
                                .addClass($icon.data('previous-icon'))
                                .removeData('previous-icon')
                            ;
                        }
                    }
                };
                var customization = {
                    modal: {
                        type: BootstrapDialog.TYPE_WARNING,
                        title: '',
                        message: '',
                        cancelLabel: i18next.t('actions:no'),
                        cancelClass: 'btn-danger',
                        okLabel: i18next.t('actions:yes'),
                        okClass: 'btn-success'
                    },
                    error: {
                        type: BootstrapDialog.TYPE_DANGER,
                        title: i18next.t('errors:unknown.title'),
                        message: i18next.t('errors:unknown.message')
                    },
                    /**
                     * Called to build the data to send.
                     *
                     * @returns {*} The data.
                     */
                    buildData: function() {
                        return null;
                    },
                    /**
                     * Triggered before submitting the request.
                     *
                     * @returns {Boolean} `true` to send the request, `false` otherwise.
                     */
                    onSubmit: function() {
                        return true;
                    },
                    /**
                     * Triggered on successful call.
                     */
                    onSuccessful: function() {
                        BO.loadUrl(location.href);
                    },
                    /**
                     * Triggered when user clicks on the cancel button.
                     */
                    onCancelled: $.noop,
                    /**
                     * Triggered on failed call.
                     *
                     * @param {Object} xhr jQuery's XHR object.
                     */
                    onFailed: function(xhr) {
                        var type = customization.error.type;
                        var title = customization.error.title;
                        var message = customization.error.message;

                        if('object' === typeof xhr.responseJSON && null !== xhr.responseJSON) {
                            type = xhr.responseJSON.type || type;
                            title = xhr.responseJSON.title || title;
                            message = xhr.responseJSON.message || message;
                        }

                        BootstrapDialog.show({
                            type: type,
                            title: title,
                            message: message
                        });
                        stopLoader($(this));
                    }
                };
                $.extend(true, customization, options);

                return function(event) {
                    // Prevent default behavior
                    event.preventDefault();

                    // Initialize vars
                    var $this = $(this);

                    // Is it already deleting?
                    if($this.data('loading')) {
                        return;
                    }

                    BootstrapDialog.confirm({
                        type: customization.modal.type,
                        title: customization.modal.title,
                        message: customization.modal.message,
                        closable: true,
                        btnCancelLabel: customization.modal.cancelLabel,
                        btnCancelClass: customization.modal.cancelClass,
                        btnOKLabel: customization.modal.okLabel,
                        btnOKClass: customization.modal.okClass,
                        callback: function(result) {
                            if(!result) {
                                customization.onCancelled.call($this.get(0));

                                return;
                            }

                            $this.data('loading', true);
                            startLoader($this);

                            if(!customization.onSubmit.call($this.get(0))) {
                                stopLoader($(this));
                                return;
                            }

                            BO
                                .doPost(
                                    $this.data('url') || $this.attr('href'),
                                    customization.buildData.bind($this.get(0)),
                                    undefined,
                                    customization.onSuccessful.bind($this.get(0)),
                                    customization.onFailed.bind($this.get(0)),
                                    {},
                                    false
                                )
                                .always(function() {
                                    $this.removeData('loading');
                                })
                            ;
                        }
                    });
                };
            },
            /**
             * Toggles a checkbox's checked status.
             *
             * @param {Object} [options] Options to customize the call.
             * @param {Object} [options.headers] Additional headers for the request.
             * @param {boolean} [options.useLoader] `true` to use the default loader, `false` otherwise.
             * @param {string} [options.method] Default call method.
             * @param {Function} [options.onSuccess] Success callback.
             * @param {Function} [options.onError] Error callback.
             * @returns {Function} The newly built function.
             */
            toggleStatus: function(options) {
                var customization = {
                    headers: {},
                    useLoader: true,
                    method: 'POST',
                    /**
                     * Triggered when call is successful.
                     *
                     * @param {boolean} checked The checked status.
                     */
                    onSuccess: function(checked) {
                        $(this).switchable('checked', checked);
                    },
                    /**
                     * Triggered when call fails.
                     *
                     * @param {Object} xhr The jQuery XHR object.
                     */
                    onError: function(xhr) {
                        var $checkbox = $(this);
                        var type = BootstrapDialog.TYPE_DANGER;
                        var title = i18next.t('errors:toggle_status.title');
                        var message = i18next.t('errors:toggle_status.message');
                        var checked = null;

                        if('object' === typeof xhr.responseJSON && null !== xhr.reponseJSON) {
                            type = xhr.responseJSON.type || type;
                            title = xhr.responseJSON.title || title;
                            message = xhr.responseJSON.message || message;
                            checked = 'boolean' === typeof xhr.responseJSON.checked || null;
                        }

                        $checkbox.switchable('checked', 'boolean' === typeof checked ? checked : !$checkbox.prop('checked'));
                        BootstrapDialog.show({
                            type: type,
                            title: title,
                            message: message
                        });
                    }
                };
                $.extend(true, customization, options);

                return function() {
                    // Initialize
                    var $checkbox = $(this).prop('disabled', true);

                    BO
                        .doRequest(
                            $checkbox.data('method') || customization.method || 'POST',
                            $checkbox.data('url'),
                            null,
                            null,
                            customization.onSuccess.bind(this),
                            customization.onError.bind(this),
                            customization.headers,
                            customization.useLoader
                        )
                        .always(function() {
                            $checkbox.prop('disabled', false);
                        })
                    ;
                };
            }
        };
    })();

    // Export methods
    window.BO = BO;
})();

$(function() {
    // Initialize vars
    var $body = $('body');
    var $wrapper = $('#wrapper');

    // Bind event handlers
    // <a href="/url" data-ajax="true">...</a> or <span data-ajax="/url">...</span>
    $body.on('click', '[data-ajax]', function(event) {
        // Prevent default behavior
        event.preventDefault();

        // Initialize vars
        var $this = $(this);
        var url = $this.attr('href') || $this.data('ajax') || null;

        if(null !== url && '#' !== url) {
            BO.loadUrl(url);
        }
    });

    $(window).on('popstate', function() {
        BO.loadUrl(document.location.href);
    });

    // Customized <select> need JavaScript to change their color when an actual value is provided
    var onSelectValueChanged = function() {
        var $select = $(this);
        var value = $select.val();

        if('' !== value) {
            $select.addClass('not-empty');
        }
        else {
            $select.removeClass('not-empty');
        }
    };
    var applyCustomSelectStyle = function() {
        onSelectValueChanged.call(this);
    };

    // Apply or remove custom style on change
    $body.on('change', 'select.select', onSelectValueChanged);

    // Apply custom select style at page load
    $('select.select').each(applyCustomSelectStyle);

    // Apply custom checkboxes style at page load
    $('input[type="checkbox"].switchable').each(function() {
        var $this = $(this);

        $this.switchable({readonly: $this.hasClass('switchable-readonly')});
    });

    $body.on('bo:loading-url:after', function() {
        // Apply custom select style after loading a new page
        $wrapper.find('select.select').each(applyCustomSelectStyle);
        $wrapper.find('input[type="checkbox"].switchable').each(function() {
            var $this = $(this);

            $this.switchable({readonly: $this.hasClass('switchable-readonly')});
        });
    });

    // A modal that is marked temporary should be removed when hidden
    $body.on('hidden.bs.modal', '.modal[data-temporary="true"]', function() {
        $(this).remove();
    });
});
